import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COUPON_QUERY";
import { AdminCouponListItem } from "@sellernote/_shared/src/types/forwarding/coupon";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { convertCouponKindToKorean } from "@sellernote/_shared/src/utils/forwarding/coupon";
import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import RegisterCoupon from "./RegisterCoupon";

type CellId = keyof AdminCouponListItem | "startAndEndDate";

const Coupon = () => {
  const history = useHistory();

  const { t } = useTranslation("coupon");

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [showsRegisterCouponModal, setShowsRegisterCouponModal] =
    useState(false);

  const { data: couponData, refetch: refetchCouponData } =
    ADMIN_COUPON_QUERY.useGetAdminCouponList(
      {
        page: currentPage,
        perPage: 25,
      },
      true
    );

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "campaignName",
        disablePadding: false,
        label: "쿠폰명",
      },
      {
        id: "kind",
        disablePadding: false,
        label: "쿠폰형식",
      },

      {
        id: "code",
        disablePadding: false,
        label: "코드",
      },

      {
        id: "discountAmount",
        disablePadding: false,
        label: "할인 금액",
      },

      {
        id: "startAndEndDate",
        disablePadding: false,
        label: "시작/종료일",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "등록일",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!couponData?.list) return [];
    return couponData?.list.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        campaignName: v.campaignName,
        kind: convertCouponKindToKorean({ couponKind: v.kind, t }),
        code: v.code,
        discountAmount: v.discountAmount,
        startAndEndDate: `${toFormattedDate(
          v.startDate,
          "YYYY.MM.DD"
        )} / ${toFormattedDate(v.endDate, "YYYY.MM.DD")}`,
        createdAt: `${toFormattedDate(v.createdAt, "YYYY.MM.DD")}`,
        handleRowClick() {
          history.push(`/coupon/${v.id}`);
        },
      };

      return row;
    });
  }, [couponData?.list, history, t]);

  return (
    <Layout title={"쿠폰"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pt: 1,
          px: 1,
          background: "#fff",
        }}
      >
        <Button
          key="create-campaign"
          variant={"contained"}
          onClick={() => setShowsRegisterCouponModal(true)}
        >
          캠페인 생성
        </Button>
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <LegacyTable
          headCells={headCells}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">
                총 {couponData?.total || 0}건
              </Typography>,
            ],
          }}
          pagination={{
            rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
            totalCount: couponData?.total || 0,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
          }}
        />
      </Box>
      {showsRegisterCouponModal && (
        <RegisterCoupon
          refetchCouponData={refetchCouponData}
          showsRegisterCouponModal={showsRegisterCouponModal}
          setShowsRegisterCouponModal={setShowsRegisterCouponModal}
        />
      )}
    </Layout>
  );
};

export default withRequireAuth(Coupon);
