import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Button, Stack } from "@mui/material";

import { UPDATE_PO_NUMBER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminOrder";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

export default function EditPONumber({
  poId,
  poNumber,
}: {
  poId: number;
  poNumber: string;
}) {
  const [showsEditModal, setShowsEditModal] = useState(false);

  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: updatePONumber, isLoading } =
    ADMIN_ORDER_QUERY.useUpdatePONumber({
      poId,
      onSuccess: () => {
        handleSnackbarOpen("수정되었습니다.", "success");
        // TODO: PO번호 수정후 얼마간은 조회시 오류가 나는데, API쪽에 문의해둔 상태.
        queryClient.invalidateQueries(
          ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({ id: poId })
        );
        setShowsEditModal(false);
      },
      onError(err) {
        if (err?.code === 409) {
          handleSnackbarOpen("이미 존재하는 PO번호입니다.", "error");
        } else {
          handleSnackbarOpen(
            `수정하지 못했습니다. ${err?.code} ${err?.error} `,
            "error"
          );
        }
      },
    });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<UPDATE_PO_NUMBER_REQ>({
    defaultValues: {
      poNumber,
    },
    shouldUnregister: true,
  });

  const handleEditClick = () => {
    setShowsEditModal(true);
  };

  const onSubmit = (data: UPDATE_PO_NUMBER_REQ) => {
    updatePONumber(data);
  };

  return (
    <>
      <ModeEditIcon
        color="primary"
        cursor="pointer"
        onClick={handleEditClick}
      />

      {showsEditModal && (
        <Modal
          isOpened={showsEditModal}
          handleClose={() => {
            setShowsEditModal(false);
          }}
          modalBody={
            <Stack
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              width="300px"
              spacing={5}
            >
              <TextFieldWithReactHookForm
                name={"poNumber"}
                label="PO번호"
                control={control}
                needsTrimOnChange={true}
              />

              <Button
                type="submit"
                variant="contained"
                disabled={!isDirty || isLoading}
              >
                수정
              </Button>
            </Stack>
          }
        />
      )}
    </>
  );
}
